import { Component, Inject, OnDestroy, OnInit, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DocumentUploadDetailComponent } from './document-upload-detail/document-upload-detail.component';
import {
  CategoryService,
  DocumentService
} from 'c4p-portal-util';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ALLOWED_FILE_TYPES, MAXIMUM_FILE_LIMIT, MAXIMUM_FILE_SIZE } from '../../const/file-types.const';
import { ActionType, SIDE_SHEET_CONTENT, SideSheetButtonEvent, SideSheetButtonListener, SideSheetContent, SideSheetService } from 'tuula-common';

@Component({
  selector: 'app-document-upload-sidesheet',
  templateUrl: './document-upload-sidesheet.component.html',
  styleUrl: './document-upload-sidesheet.component.css'
})
export class DocumentUploadSidesheetComponent implements OnInit, OnDestroy, SideSheetButtonListener {
  private readonly destroyed$ = new Subject<boolean>();

  files: File[] = [];
  @ViewChildren(DocumentUploadDetailComponent)
  uploadDetails!: QueryList<DocumentUploadDetailComponent>;
  categories = [];
  services = [];
  carefileId: string;
  applicationId: string;
  careprogramId: string;
  clientId: string;
  @ViewChild('fileInput') fileInput: any;


  constructor(public documentService: DocumentService,
              private sideSheetService: SideSheetService,
              private categoryService: CategoryService,
              private toastr: ToastrService,
              private translate: TranslateService,
              @Optional()
              @Inject(SIDE_SHEET_CONTENT)
              public sideSheetContent: SideSheetContent) {

  }

  ngOnInit(): void {
    this.getCategories();
    this.services = this.sideSheetContent?.data?.services;
    this.carefileId = this.sideSheetContent?.data?.carefileId;
    this.applicationId = this.sideSheetContent?.data?.applicationId;
    this.careprogramId = this.sideSheetContent?.data?.careprogramId;
    this.clientId = this.sideSheetContent?.data?.clientId;
  }

  ngOnDestroy(): void {
    this.destroyed$?.next(true);
    this.destroyed$?.complete();
  }

  onDragOver(event: DragEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
  }

  onDragLeave(event: DragEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
  }

  onDrop(event: DragEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
    if (this.files?.length > MAXIMUM_FILE_LIMIT) {
      this.toastr?.error(this.translate.instant('document.messages.maximumFileLimit'));
      return;
    }
    if (event?.dataTransfer?.files) {
      if (event.dataTransfer.files.length + this.files?.length > MAXIMUM_FILE_LIMIT) {
        this.toastr?.error(this.translate.instant('document.messages.maximumFileLimit'));
        return;
      }
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        if (!ALLOWED_FILE_TYPES.includes(event.dataTransfer.files[i].name.split('.').pop().toLowerCase())) {
          this.toastr?.error(this.translate.instant('document.messages.invalidFileExtension'));
          return;
        }
        if (event.dataTransfer.files[i].size > MAXIMUM_FILE_SIZE) {
          this.toastr?.error(this.translate.instant('document.messages.invalidFileSize'));
          return;
        }
        this.files?.push(event.dataTransfer.files[i]);
      }
    }
  }

  startAllUploads(): void {
    this.uploadDetails?.forEach((uploadDetail) => {
      if (uploadDetail?.documentForm?.valid && !uploadDetail.isUploading && uploadDetail.uploadProgress === 0) {
        uploadDetail?.uploadDocument();
      }
    });
  }

  deleteDocument(file: File): void {
    this.files = this.files?.filter((f) => f !== file);
  }

  openFileBrowser(): void {
    if (this.files?.length > MAXIMUM_FILE_LIMIT) {
      this.toastr?.error(this.translate.instant('document.messages.maximumFileLimit'));
      return;
    }
    this.fileInput?.nativeElement?.click();
  }

  onFileSelected(event: Event): void {
    const input = event?.target as HTMLInputElement;
    const files = input?.files;

    if (files?.length + this.files?.length > MAXIMUM_FILE_LIMIT) {
      this.toastr?.error(this.translate.instant('document.messages.maximumFileLimit'));
      return;
    } else if (files) {
      for (let i = 0; i < files.length; i++) {
        if (!ALLOWED_FILE_TYPES.includes(files[i].name.split('.').pop().toLowerCase())) {
          this.toastr?.error(this.translate.instant('document.messages.invalidFileExtension'));
          return;
        }
        if (files[i].size > MAXIMUM_FILE_SIZE) {
          this.toastr?.error(this.translate.instant('document.messages.invalidFileSize'));
          return;
        }
        this.files?.push(files[i]);
      }
      input.value = '';
    }
  }

  private getCategories(): void {
    this.categoryService?.getCategories()?.subscribe((categories) => {
      this.categories = categories;
    });
  }

  onClickButton(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE || event?.button?.actionType === ActionType.CANCEL) {
      this.sideSheetService.closeSideSheet();
    } else if (event?.button?.actionType === "upload") {
      this.startAllUploads();
      const progressCheckInterval = setInterval(() => {
        let uploadStatus = 0;
        this.uploadDetails.map((uploadDetail) => uploadStatus += uploadDetail.uploadProgress);
        if (uploadStatus === this.files.length * 100) {
          this.sideSheetService.closeSideSheet();
          clearInterval(progressCheckInterval);
        }
      }, 1000);
    }
  }

}
