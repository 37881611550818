<div class="tuula-table-container">
  <div style="display: flex; flex-direction: column">
    <div class="tuula-table-top-bar" [ngStyle]="{'border-bottom': customTitleElement ? 0 : 1} ">
      <div class="tuula-table-title-container">
      <span class="tuula-table-title">
        {{ title() | translate }}
      </span>
      </div>
      <div class="tuula-table-controls">
        <div class="tuula-table-controls-sub-container">
          <ng-container *ngTemplateOutlet="tableControls()">
          </ng-container>
        </div>
        <div class="tuula-table-controls-sub-container">
          @if(tableFilteringButtonVisible()){
            <button class="tuula-table-filter-button" (click)="onToggleFilter()">
              <img src="/assets/icons/filter2.svg" alt="" />
              <span class="tuula-table-filter-button-text">{{'notification.labels.Filters' | translate}}</span>
              <div class="arrow">
                <img src="/assets/icons/arrow-down2.svg" alt="" *ngIf="!filterPanelOpened" />
                <img src="/assets/icons/arrow-up2.svg" alt="" *ngIf="filterPanelOpened" />
              </div>
            </button>
          }
          @if(tableSettingsVisible()){
            <button (click)="onOpenTableSettings()" class="tuula-table-control-button">
              <mat-icon>settings</mat-icon>
            </button>
          }
        </div>
      </div>
    </div>
    @if(customTitleElement){
      <div class="tuula-table-title-template">
        <ng-container *ngTemplateOutlet="customTitleElement">
        </ng-container>
      </div>
    }
  </div>

  @if (tableLoading()) {
    <app-tuula-table-skeleton
    [columns]="columns"
    [tableRowSize]="tableRowSize"
    [totalRowWidth] = "totalRowWidth"
    [defaultCellWidth]="defaultCellWidth"></app-tuula-table-skeleton>
  }
    <ng-container *ngTemplateOutlet="tuulaTable"></ng-container>
  
  <mat-paginator [pageSizeOptions]="pageSizeOptions()"
                 [showFirstLastButtons]="false"
                 [length]="pagination()?.totalDocs"
                 (page)="onPageChanged($event)"
                 [pageSize]="pagination()?.pageSize"
                 [pageIndex]="pagination()?.page - 1"
  >
  </mat-paginator>
</div>


<ng-template #tuulaTable>
  <mat-table [dataSource]="dataSource" [attr.loading]="tableLoading() ? '' : null" class="tuula-table" matSort (matSortChange)="onSortChanged($event)" cdkDropList
    cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    @for (column of columns; track column) {
    <ng-container [matColumnDef]="column.prop" [sticky]="!mobileView && column.frozenLeft" [stickyEnd]="column.frozenRight">
      <mat-header-cell cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".tuula-table" [cdkDragDisabled]="column.draggable === false" *matHeaderCellDef
        mat-sort-header [attr.disabled]="column.sortable === false ? '' : null"
        [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }">
        @if (column.headerTemplate) {
        <ng-container *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
        </ng-container>
        } @else {
        <span>{{ column.name | translate }}</span>
        }
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index"
        [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }">
        @if (column.cellTemplate) {
        <ng-container
          *ngTemplateOutlet="column.cellTemplate; context: { row: row, rowIndex: i, rowValue: {row, column} | functionCall : getNestedValue }">
        </ng-container>
        } @else {
        <span [matTooltip]="{row, column} | functionCall : getNestedValue">
          {{ {row, column} | functionCall : getNestedValue }}
        </span>
        }
      </mat-cell>
    </ng-container>

    <!-- Filter Columns -->
    <ng-container matColumnDef="{{column.prop + filterColumnNamePostfix}}" [sticky]="!mobileView && column.frozenLeft"
      [stickyEnd]="column.frozenRight">
      <mat-header-cell *matHeaderCellDef
        [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth, 'justify-content': column.justify ? column.justify :'start' }">
        @if (filterPanelOpened){
        <app-tuula-table-filter-header-column style="width:100%" [column]="column"
          (filterChanged)="onApplyFilter($event)"
          (filterCleared)="onClearFilter()"></app-tuula-table-filter-header-column>
        }
      </mat-header-cell>
    </ng-container>
    }
    <mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: stickyMode"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-header-row>
    <mat-header-row [ngClass]="{'invisible-header-row' : !filterPanelOpened}"
      *matHeaderRowDef="tableDisplayedFilterColumns, sticky: stickyMode"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
      [ngClass]="[tableRowSize === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"
      [ngStyle]="{ 'min-width.px': totalRowWidth }"></mat-row>

    <tr class="mat-mdc-row mdc-data-table__row no-data-row" *matNoDataRow [ngStyle]="{ 'min-width.px': totalRowWidth }">
      <td class="mat-mdc-cell mdc-data-table__cell" translate>general.labels.NoRecordFound</td>
    </tr>

  </mat-table>
</ng-template>

