import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetHostDirective } from './side-sheet-host.directive';

const moduleComponents = [
  SideSheetHostDirective
];
@NgModule({
  imports: [CommonModule,...moduleComponents],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
})
export class DirectivesModule {}
